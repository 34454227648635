
if ( $('.publication-home.publication-wrapper').length > 0 ) {

    var $grid = $('.publication-grid').masonry({
        itemSelector: '.publication-block'
    });
    // layout Masonry after each image loads
    $grid.imagesLoaded().progress( function() {
        $grid.masonry('layout');
    });

}