
$(function() {

    $('.mobile-block-menu .icon_search').click(function() {

        var _form = $('.form_search_mobile');

        if ( _form.hasClass('active') ) {

            _form.removeClass('active').slideUp();

        } else {

            _form.addClass('active').slideDown();

        }

    });

});