
$(function() {

    if ( $('.product-accordion-value li.green').length > 0 ) {

        $('.product-accordion-value li.green').mouseover(function() {

            $(this).find('.tip').stop(true).fadeIn();

        });
        
        $('.product-accordion-value li.green').mouseleave(function() {

            $(this).find('.tip').stop(true).fadeOut();
            
        });

    }

});