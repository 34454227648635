$(function () {

  var $selectTrigger = $('.top-nav select.link, .mobile-menu select.link');

  $selectTrigger.change(function() {

    var value = $(this).val(),
      translatedRouteFR = $('#translatedUrl_fr').val(),
      translatedRouteEN = $('#translatedUrl_en').val();

    location.href = '/changelang?code=' + value + '&url_fr=' + translatedRouteFR + '&url_en=' + translatedRouteEN;
  });
});
