$(document).ready(function(){

    if ( $(window).width() > 767 ) {

        function scrollSystem() {

            var scrollTop = $(window).scrollTop(),
                windowHeight = $(window).height(),
                _menu = $('#menu'),
                menuHeight = _menu.outerHeight(),
                _list = $('.products-listing'),
                listHeight = parseFloat(_list.offset().top + _list.height()),
                toTop = _menu.offset().top,
                _filters = $('.products-products .filters'),
                filtersTop = _filters.offset().top,
                maxHeight = 0,
                sideTop = 0,
                _scroll = $('.products-menu .scroll');

            // Products list
            // _list.css('min-height', parseFloat(windowHeight + menuHeight));
            _list.css('height', parseFloat(windowHeight + menuHeight));

            if (_menu.hasClass('fixed') && !_filters.hasClass('fixed') && !_filters.hasClass('bottom') && parseInt(_menu.offset().top + menuHeight) > filtersTop) {
                
                _filters.addClass('fixed').css('top', menuHeight);
                _list.find('.products-products').css('padding-top', _filters.outerHeight());
            }

            if (_filters.hasClass('fixed') && parseInt(_menu.offset().top + menuHeight) <= $('.products-listing').offset().top) {

                _filters.removeClass('fixed').css('top', 'inherit');
                _list.find('.products-products').css('padding-top', 'inherit');
            }

            if (parseFloat(scrollTop + menuHeight + _filters.outerHeight()) > listHeight && !_filters.hasClass('bottom')) {

                _filters
                    .css('top', parseFloat(filtersTop - _filters.outerHeight()))
                    .removeClass('fixed')
                    .addClass('absoluted bottom');
            }

            if (parseFloat(scrollTop + menuHeight) < filtersTop && _filters.hasClass('bottom')) {

                _filters
                    .css('top', parseFloat(menuHeight))
                    .addClass('fixed')
                    .removeClass('absoluted bottom');
            }

            // Side menu
            if (parseFloat(scrollTop + windowHeight) > $('.footer').offset().top) {

                if (!_scroll.hasClass('bottom')) {

                    _scroll.css('position', 'absolute');
                    _scroll.removeClass('fixed').addClass('bottom');
                }

                maxHeight = parseFloat($('.footer').offset().top - ( _menu.offset().top + _menu.outerHeight() ) - parseInt(_scroll.css('margin-left')) * 2);

                if (maxHeight > 300) {

                    sideTop = parseFloat(scrollTop + menuHeight);
                } else {

                    sideTop = parseFloat($('.footer').offset().top - maxHeight - parseInt(_scroll.css('margin-left')) * 2);
                }
            } else if ( _filters.hasClass('fixed') && parseFloat(_menu.offset().top + menuHeight) > _list.offset().top) {

                _scroll.removeClass('bottom').addClass('fixed');
                maxHeight = parseFloat(( windowHeight - _menu.outerHeight() ) - parseInt(_scroll.css('margin-left')) * 2);
                sideTop = _menu.outerHeight();
                _scroll.css('position', 'fixed');
            } else {

                _scroll.removeClass('fixed');
                maxHeight = parseFloat(( scrollTop + windowHeight ) - ( $('.products-menu').offset().top ) - parseInt(_scroll.css('margin-left')) * 2);
                sideTop = filtersTop;
                _scroll.css('position', 'absolute');
            }

            _scroll.css('height', maxHeight);
            _scroll.css('top', sideTop);


            // Height of cell
            _list.css('min-height', parseFloat(windowHeight + menuHeight));
        }

        if ($(window).width() > 767 && $('.products-listing').length > 0) {

            scrollSystem();

            $(window).scroll(function () {

                scrollSystem();
            });
        }
    }
});