
$(function() {

    if ( $('.call-for-video').length > 0 ) {

        $('body').on('click', '.call-for-video', function() {

            $('.videos-item').removeClass('active');
            $(this).addClass('active');

            player.destroy();

            var video_id = $(this).data('video'),
                _video = $('.main-video');

            //player.stopVideo();

            if ( _video.css('display') == 'none' ) {

                _video.slideDown(400);
            }

            player = new YT.Player('player', {
                playerVars: { 'autoplay': 0, 'controls': 1,'autohide':1, 'showinfo':0, 'rel':0, 'loop':1},
                videoId: video_id,
                events: {
                    'onReady': onPlayerReady}
            });

            _video.slideDown(400);

            if ( $('#menu .right-block').css('display') != 'none' ) {

                var destination = _video.offset().top,
                    sticky = $('#menu').height(),
                    totop = destination - sticky;
            } else {

                var totop = _video.offset().top;
            }

            $("html, body").animate({scrollTop: totop }, 400);

            setTimeout(function() {

                player.playVideo();

            }, 400);

        });

    }

});