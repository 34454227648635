
$(function() {

    if ( $('input[type=file]').length > 0 ) {

        var baseHTML = $('input[type=file]').closest('.form-group').find('.form-file-label label').html();

        console.log( baseHTML );

        $('input[type=file]').change(function(e) {

            var fileName = '';
            if( this.files && this.files.length > 1 ) {

                fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
                $(this).closest('.form-group').find('.form-file-label label').html(fileName);
            }
            else if( this.files && this.files.length == 1 ) {

                fileName = e.target.value.split( '\\' ).pop();
                $(this).closest('.form-group').find('.form-file-label label').html(fileName);
            }
            else {

                $(this).closest('.form-group').find('.form-file-label label').html( baseHTML );
            }

            return false;
        });

    }

});