
$(function() {

    $('#menu .link.search').click(function() {
        
        var _icon_search = $(this).find('.icon_search'),
            _icon_close = $(this).find('.icon_close'),
            _search = $('#search-input');

        if ( _search.hasClass('open') ) {

            _icon_close.hide();
            _icon_search.show();
            _search.removeClass('open');
        } else {

            _icon_search.hide();
            _icon_close.show();
            _search.addClass('open').find('input').focus();
        }

    });

});