
$(function() {

    if ( $('.products-products, .partial-search').length > 0) {

        function checkPosition() {

            var bottomWindow = parseInt( $(window).scrollTop() + $(window).height() ),
                buttonPosition = parseInt( $('.next-products .button').offset().top - 100 );

            if ( !$('.products-products, .partial-search').hasClass('loading') &&  bottomWindow > buttonPosition && $('.products-products .next-products, .partial-search .next-products').css('display') != 'none' ) {

                $('.products-products .next-products .button, .partial-search .next-products .button').click();

            }

        }

        $(window).scroll(function(){

            checkPosition();

        });

        checkPosition();

    }

});