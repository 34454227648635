
$(function() {

    if ( $('.share-cell.videos .videos-choice').length > 0 ) {

        $('.share-cell.videos').click(function(e) {

            var _target = $(e.target);

            if ( _target.hasClass('share-cell') && _target.hasClass('active') ) {

                _target.removeClass('active');
                $('.videos-choice').fadeOut();

            } else if ( _target.hasClass('share-cell') && !_target.hasClass('active') ) {

                _target.addClass('active');
                $('.videos-choice').fadeIn();
                
            }

        });

        $('.videos-choice .close').click(function() {

            $('.share-cell.videos').removeClass('active');
            $('.videos-choice').fadeOut();

        });

    }

});