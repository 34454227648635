
$(function() {

    $( window ).scroll(function(){

        var _sticky = $( '#menu' ),
            thisTop = parseInt($( window ).scrollTop() + _sticky.position()['top']),
            _anchor = _sticky.next(),
            toTop = $( window ).scrollTop(),
            stickyTop = 0,
            stickyHeight = _sticky.outerHeight();

        if ( !_sticky.hasClass('fixed') && toTop > _sticky.offset().top && $( window ).width() > 991 ){

            _sticky.addClass('fixed').css('top', stickyTop).next()
                    .css('padding-top', stickyHeight + 'px');
        } else if ( _sticky.hasClass('fixed') && thisTop < _anchor.offset().top ){

            _sticky.removeClass('fixed').next()
                .css('padding-top','0');
        }

    });

});