
$(function() {

    if ( $('.inspiration-momenti').length > 0 ) {

        var lightbox = lity();

        $('.inspiration-image').click(function() {

            var _image = $(this).data('big');

            lightbox(_image);

        });

    }

});