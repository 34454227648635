
$(function() {

    if ( $( window ).width() < 768 ) {

        $('.footer-block .title-underlined').click(function() {

            var _sections = $('.footer-block'),
                _section = $(this).closest('.footer-block');

            if ( _section.hasClass('opened') ) {

                _section.removeClass('opened')
                    .find('.content').slideUp(200);
                _section.find('.tcon').removeClass('tcon-transform');
            } else {

                _sections.removeClass('opened')
                    .find('.content').slideUp(200);
                _sections.find('.tcon').removeClass('tcon-transform');

                _section.addClass('opened')
                    .find('.content').slideDown(200);
                _section.find('.tcon').addClass('tcon-transform');
            }

        });

    }

});