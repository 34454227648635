$(function () {

  var _changeStateTrigger = $('#changeStateButton'),
    _changeStatePopup = $('.changeState_popup'),
    _closePopup = $('.changeState_popup .close_newsletter');

  if (_changeStateTrigger.data('popup')) {

    setTimeout(function () {

      _changeStateTrigger.click();
    }, 1000);
  }

  _changeStateTrigger.click(function () {

    _changeStatePopup.addClass('active');
  });

  _closePopup.click(function () {

    _changeStatePopup.removeClass('active');
  });

  $('body').on('click', '.list_states .item_state:not(.active)', function() {

    var value = $(this).data('value');

    $('.list_states .item_state').removeClass('active');
    $(this).addClass('active');

    location.href = '/changestate/' + value;
  });
});