
$(window).load(function(){

    if ( $('#hero-banner-1').length > 0 ) {

        resizeHero();

        $(this).resize(function() {
            resizeHero();
        });
    }

    function resizeHero () {

        var window = $(this).height(),
            position = $('#hero-banner').position()['top'],
            height = window - position;

        $('#hero-banner').css('height', height);
    }
});