
$(function() {

    if ( $('#hero-banner').length ) {

        var _newsletterPopup = $('.newsletter_popup'),
            _closePopup = $('.newsletter_popup .close_newsletter'),
            _submitPopup = _newsletterPopup.find('button.submit'),
            _formPopup = _newsletterPopup.find('form');

        _submitPopup.click(function() {

            _newsletterPopup.removeClass('active');
            _formPopup.submit();
        });

        _closePopup.click(function() {

            _newsletterPopup.removeClass('active');
        });

        if(localStorage.getItem('popState') != 'shown' && ( !$('#changeStateButton').length || !$('#changeStateButton')[0].hasAttribute('data-popup') ) ){

            _newsletterPopup.delay(4000).addClass('active');
            localStorage.setItem('popState','shown')
        }
    }
});