
$(document).ready(function () {

    if ( $('.home-products').length > 0 ) {

        // HOME PRODUCTS
        if ( $(window).width() < 520 ) {

            var myProductsSwiper = new Swiper ('.home-products .swiper-container', {
                slidesPerView: 1.3,
                slidesOffsetBefore: 20,
                slidesOffsetAfter: 20
            });
        }

        // HOME SWIPERS
        var slidePerView = 1.6,
            space = 50;

        if ( $(window).width() < 1310 ) {

            slidePerView = 1.2;
        }

        if ( $(window).width() < 992 ) {

            slidePerView = 1.3;
            space = 20;
        }

        var myStylesSwiper = new Swiper ('.home-swipers .swiper-container', {
            slidesPerView: slidePerView,
            slidesOffsetBefore: space,
            slidesOffsetAfter: space,
            spaceBetween: space,
            pagination: '.home-swipers .swiper-pagination',
            paginationClickable: true
        });

    }
});