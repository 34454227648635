
$(function() {

    $('#mobile-menu-toogle').click(function() {

        if ( $(this).hasClass('tcon-transform') ) {

            close_menu();
        } else {

            open_menu();
        }

    });

    function open_menu() {

        $('#mobile-menu-overlay').fadeIn(400);
        $('#mobile-menu-panel').addClass('open');
        $('#mobile-menu-toogle').addClass('tcon-transform');
        $('body').css('overflow', 'hidden');
    }

    function close_menu() {

        $('#mobile-menu-overlay').fadeOut(400);
        $('#mobile-menu-panel').removeClass('open');
        $('#mobile-menu-toogle').removeClass('tcon-transform');
        $('body').css('overflow', 'auto');
    }

});