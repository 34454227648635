
$(function() {

    if ( $('.publication-share, .product-share').length > 0 ) {

        /**
         * Share Btns Popup windows
         * ===========================================
         *
         */
        $('.publication-share .share, .product-share .share-icon').click(function (event) {
            event.preventDefault();
            //window.open($(this).attr("href"), "popupWindow", "width=600,height=600,scrollbars=yes");

            PopupCenter($(this).attr("href"), 600, 600);
        });

        function PopupCenter(url, w, h) {
            // Fixes dual-screen position                         Most browsers      Firefox
            var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
            var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

            var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

            var left = ((width / 2) - (w / 2)) + dualScreenLeft;
            var top = ((height / 2) - (h / 2)) + dualScreenTop;
            var newWindow = window.open(url, 'Riobel', 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

            // Puts focus on the newWindow
            if (window.focus) {
                newWindow.focus();
            }
        }

    }

});