
$(function() {

    if ( $('.product-item').length > 0 ) {

        $( window ).resize(function() {

            resizeScreen();

        });

        resizeScreen();

        function resizeScreen() {

            if ( $(window).width() > 1070 ) {

                var height = parseInt( $(window).height() - ( $('.header').height() + $('.share-row').height() ) );

                $('.product-item').css('height', height);

            } else {

                $('.product-item').css('height', 'auto');

            }

        }




        // IF NO VIDEOS

        if ( $('.share-row .share-cell').length < 3 && $(window).width() > 767 ) {

            $('.share-row .share-cell').css('width', '50%');
        } else if ( $('.share-row .share-cell').length < 4 && $(window).width() > 767 ) {

            $('.share-row .share-cell').css('width', '33.33334%');
        }
        
        // change color product image
        $('.change-image').on('click',function () {
            var active = $('.product-background').data('active');
            var hd = $(this).data('image-hd');
            if (hd && active!=hd){
                $('.product-background').data('active',hd);
                $('.product-background').css('background-image',"url('"+hd+"')");
            }
        })
    }

});