
$(function() {

    function GetIEVersion() {
        var sAgent = window.navigator.userAgent;
        var Idx = sAgent.indexOf("MSIE");

        // If IE, return version number.
        if (Idx > 0)
            return parseInt(sAgent.substring(Idx+ 5, sAgent.indexOf(".", Idx)));

        // If IE 11 then look for Updated user agent string.
        else if (!!navigator.userAgent.match(/Trident\/7\./))
            return 11;

        else
            return 0; //It is not IE
    }

    if (GetIEVersion() > 0 && GetIEVersion() < 9) {

        if ( $.cookie('IEalert') == 'yes' ) {

            $('#IE-alert').show();

        } else {

            $.cookie('IEalert', 'yes');

        }

    }

    $('#IE-alert .close').click(function() {

        $('#IE-alert').hide();
        $.cookie('IEalert', 'no');

    });

});