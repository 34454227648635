
$(function() {

    if ( $('.contact-table').length > 0 ) {

        $('.contact-place-title').click(function() {

            var _previous = $('.contact-place.active'),
                _place = $(this).closest('.contact-place');

            if ( _place.hasClass('active') ) {

                closeBox( _previous );

            } else {

                closeBox( _previous );
                openBox( _place );

            }

        });

        function closeBox(_element) {

            _element.removeClass('active')
                .find('.contact-place-dropdown').slideUp(400);
            _element.find('.tcon').removeClass('tcon-transform');

        }

        function openBox(_element) {

            _element.addClass('active')
                .find('.contact-place-dropdown').slideDown(400);
            _element.find('.tcon').addClass('tcon-transform');

        }

    }

});