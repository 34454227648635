$(function() {

    $('.main-nav .right-block .search-action').click(function(e) {

        $('.main-nav .right-block .link.search').click();

        e.preventDefault();

    });

});