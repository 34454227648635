
if ( $('.inspiration-wrapper').length > 0 ) {

    // $('.inspiration-wrapper').masonry({
    //     itemSelector: '.inspiration-item',
    //     percentPosition: true
    // });

    var $grid = $('.inspiration-wrapper').masonry({
        itemSelector: '.inspiration-item'
    });
    // layout Masonry after each image loads
    $grid.imagesLoaded().progress( function() {
        $grid.masonry('layout');
    });

}