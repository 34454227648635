
$(function() {

    if ( $('.support-menu-mobile').length > 0 && $(window).width() < 767 ) {

        var _support_mobile_title = $('.support-menu-mobile'),
            _support_mobile_menu = $('.support-menu');

        $('.support-menu-mobile span').html( $('.support-menu .support-menu-link.active').text() );

        _support_mobile_title.click(function() {

            if ( !_support_mobile_menu.hasClass('open') ) {

                _support_mobile_title.find('.fa')
                    .removeClass('fa-angle-double-down')
                    .addClass('fa-angle-double-up');

                _support_mobile_menu.slideDown(400)
                    .addClass('open');

            } else {

                _support_mobile_title.find('.fa')
                    .removeClass('fa-angle-double-up')
                    .addClass('fa-angle-double-down');

                _support_mobile_menu.slideUp(400)
                    .removeClass('open');

            }

        });

    }

});