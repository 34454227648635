$(function () {

  var $rohlBar = $('.thor_bar'),
    $trigger = $('.logo_thor');

  $trigger.on('click',function (e) {
    if ($(window).width() < 600) {
      e.preventDefault();
      $rohlBar.toggleClass('is-opened');
    }
  });

});