
$(function() {

    if ( $('#hero-banner').length > 0 ) {

        $('#hero-banner .arrow-down').click(function() {

            if ( $('#menu .right-block').css('display') != 'none' ) {

                var destination = $('#to_bottom').offset().top,
                    sticky = $('#menu').height(),
                    totop = destination - sticky;
            } else {

                var totop = $('#to_bottom').offset().top;
            }

            $("html, body").animate({scrollTop: totop }, 1000);

        });
    }

});