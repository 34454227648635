$(function() {

  if ($('.products-listing').length > 0) {

    var _new = [],
      _space = [],
      _style = [],
      _group = [],
      _subgroup = [],
      _collection = [],
      _param = [],
      onAjax = false,
      ajaxCall = false;

    // CLICK ON THE MENU
    $('.products-menu .menu-link').click(function(e) {

      e.preventDefault();

      // GET THE INFOS
      var group = $(this).data('group'),
        id = $(this).data('id');

      // if ( $(this).hasClass('selected') ) {
      //
      //     removeTag(group, id);
      //
      // } else {
      //
      //     addTag($(this), group, id);
      //
      // }

      $('.action-checkbox.is-active').removeClass('is-active');

      addTag($(this), group, id);

    });

    // CLICK ON THE PRODUCT COLLECTION
    $('body').on('click', '.products-list .menu-link', function() {
      // GET THE INFOS
      var group = $(this).data('group'),
        id = $(this).data('id'),
        _link = $('.products-menu .menu-link[data-group=' + group + '][data-id=' + id + ']');

      _link.click();

    });

    /**
     * On tak click event
     */
    $(document).on('click', '.products-products .tag', function(e) {

      // GET THE INFOS
      var group = $(this).data('group'),
        id = $(this).data('id');

      removeTag(group, id);

    });

    $(document).on('change', '.products-products .action-checkbox input', function(e) {
      toggleInputDiscontinued($(this));

      refreshProducts();

    });

    $('.products-products .categories-item')
      .click(function(e) {

        e.preventDefault();

        toggleCategories($(this));

        refreshProducts();

      });

    /**
     * On tak click event
     */
    $('.products-products .next-products .button').click(function(e) {

      e.preventDefault();

      refreshProducts(true);

    });

    function toggleInputDiscontinued(input) {
      var inputParent = input.parent('.action-checkbox');

      inputParent.toggleClass('is-active');
    }

    function toggleCategories(category) {
      $('.products-products .categories-item.is-active').removeClass('is-active');

      category.toggleClass('is-active');

      $('.products-products .title .subtitle').text(category.text());

    }

    /**
     * Remove tag from array
     * @param group
     * @param id
     */
    function removeTag(group, id) {

      // REMOVE NEW SEARCH
      if ($('.products-products .tags .tag').length == 1) {

        $('.products-products .tags').removeClass('full');

      }

      var _tag = $('.products-products .tag[data-group=' + group + '][data-id=' + id + ']'),
        _menu = $('.products-menu .menu-link[data-group=' + group + '][data-id=' + id + ']');

      _tag.remove();
      _menu.removeClass('selected');

      updateArrays(group, id);

      // UPDATE THE PRODUCTS
      refreshProducts();

    }

    /**
     * Remove tag from array
     * @param group
     * @param id
     */
    function addTag(_link, group, id) {

      // SET THE LINK SELECTED
      $('.products-menu .menu-link').removeClass('selected');
      _link.addClass('selected');

      // CREATE THE TAG
      var _tag = $('<div/>')
        .addClass('tag')
        .attr('data-group', group)
        .attr('data-id', id)
        .html(_link.html());

      $('.products-products .filters .tags').empty();
      $('.products-products .filters .tags').append(_tag);

      // UPDATE THE URL
      updateArrays(group, id);

      // ADD NEW SEARCH
      $('.products-listing .products-products .tags').addClass('full');

      // UPDATE THE PRODUCTS
      refreshProducts();

    }

    /**
     * Update the arrays with parameters
     * @param group
     * @param id
     */
    function updateArrays(group, id) {

      _new = [];
      _space = [];
      _style = [];
      _group = [];
      _subgroup = [];
      _collection = [];
      _param = [];

      switch (group) {

        case 'space':
          if (jQuery.inArray(id, _space) > -1) {

            _space.splice($.inArray(id, _space), 1);

          } else {

            _space.push(id);

          }

          $('.products-products .actions')
            .show();

          $('.products-products .categories')
            .show();
          $('.products-products .title .subtitle')
            .show();
          break;

        case 'style':
          if (jQuery.inArray(id, _style) > -1) {

            _style.splice($.inArray(id, _style), 1);

          } else {

            _style.push(id);

          }

          $('.products-products .actions')
            .show();

          $('.products-products .categories')
            .hide();
          $('.products-products .title .subtitle')
            .hide();
          break;

        case 'group':
          if (jQuery.inArray(id, _group) > -1) {

            _group.splice($.inArray(id, _group), 1);

          } else {

            _group.push(id);

          }

          $('.products-products .actions')
            .show();

          $('.products-products .categories')
            .hide();
          $('.products-products .title .subtitle')
            .hide();
          break;

        case 'subgroup':
          if (jQuery.inArray(id, _subgroup) > -1) {

            _subgroup.splice($.inArray(id, _subgroup), 1);

          } else {

            _subgroup.push(id);

          }

          $('.products-products .actions')
            .show();

          $('.products-products .categories')
            .hide();
          $('.products-products .title .subtitle')
            .hide();
          break;

        case 'collection':
          if (jQuery.inArray(id, _collection) > -1) {

            _collection.splice($.inArray(id, _collection), 1);

          } else {

            _collection.push(id);

          }

          $('.products-products .actions')
            .show();

          $('.products-products .categories')
            .show();
          $('.products-products .title .subtitle')
            .show();
          break;

        case 'new':
          if (jQuery.inArray(id, _new) > -1) {

            _new.splice($.inArray(id, _new), 1);

          } else {

            _new.push(id);

          }

          $('.products-products .actions')
            .show();

          $('.products-products .categories')
            .show();
          $('.products-products .title .subtitle')
            .show();
          break;
        case 'param' :
          if (jQuery.inArray(id, _param) > -1) {

            _param.splice($.inArray(id, _param), 1);

          } else {

            _param.push(id);

          }

          $('.products-products .actions')
            .show();

          $('.products-products .categories')
            .hide();
          $('.products-products .title .subtitle')
            .hide();
          break;
      }
    }

    /**
     * Refresh page with call results
     */
    function refreshProducts(addProducts) {

      if (ajaxCall) {

        ajaxCall.abort();
      }

      addProducts = typeof addProducts !== 'undefined' ? addProducts : false;

      var _general = $('.products-listing .products-products'),
        _products = $('.products-listing .product-block-row'),
        _next = $('.products-listing .next-products');

      $('.products-listing .no-results').slideUp();
      _next.slideDown();

      _general.addClass('loading');

      if (_next.css('display') == 'none') {

        _next.slideDown(200);

      }

      if (addProducts) {

        var data = {
          space: _space,
          style: _style,
          group: _group,
          subgroup: _subgroup,
          collection: _collection,
          new: _new,
          param: _param,
          page: $('.products-products .next-products').data('next')
        };
      } else {

        _products.slideUp(400);

        var data = {
          space: _space,
          style: _style,
          group: _group,
          subgroup: _subgroup,
          collection: _collection,
          new: _new,
          param: _param
        };
      }

      setTimeout(function() {
        var categoryProductsAvantage = $('.products-products .categories-item.is-active');
        var inputDiscontinuedActive =
          $('.action-checkbox.is-active input[value="discontinued"]').length > 0;

        var ajaxURL = new URL(PHP_REFRESH);
        var urlParams = new URLSearchParams(ajaxURL.search.slice(1));

        if (ajaxURL.searchParams.get('advantage')) {
          urlParams.delete('advantage');
        }

        if (ajaxURL.searchParams.get('discontinue')) {
          urlParams.delete('discontinue');
        }

        if ($('.products-products .categories').is(':visible') && categoryProductsAvantage &&
          categoryProductsAvantage.attr('data-category-slug') === 'riobel') {
          urlParams.append('advantage', '0');
        } else if ($('.products-products .categories').is(':visible') &&
          categoryProductsAvantage.attr('data-category-slug') === 'advantage') {
          urlParams.append('advantage', '1');
        }

        if ($('.products-products .actions')
          .is(':visible') && !inputDiscontinuedActive) {
          urlParams.append('discontinue', '0');
        } else if ($('.products-products .actions')
          .is(':visible') && inputDiscontinuedActive) {
          urlParams.append('discontinue', '1');
        }

        ajaxURL.search = urlParams;

        console.log(ajaxURL.href);

        ajaxCall = $.ajax({
          url: ajaxURL.href,
          type: 'POST',
          data: data
        }).done(function(data) {

          if ($(data.data).length > 0) {

            console.log(data.data);

            // Append the new products
            if (addProducts) {

              _products.find('.products-list').append(data.data);

            } else {

              _products.find('.products-list').html(data.data);

            }

            // Get next_page
            if (data.next_page != null) {

              _general.removeClass('loading');
              _next.data('next', data.next_page);

            } else {

              _next.slideUp(200);

            }

            if (!$('.product-item').length > 0) {

              // Update the URL
              if (typeof (data.url) !== 'undefined') {
                window.location.hash = data.url;
              } else {
                history.pushState('', document.title, window.location.pathname
                  + window.location.search);
              }

            }

            // Display the products
            _products.slideDown(400);

          } else {

            $('.products-listing .no-results').slideDown();
            _next.slideUp();
          }

          ajaxCall = false;
        });
      }, 400);
    }

    $('.new_search').click(function() {

      // REINITIATE VALUES
      _new = [];
      _space = [];
      _style = [];
      _group = [];
      _subgroup = [];
      _collection = [];
      _param = [];

      // REMOVE TAGS
      $('.products-listing .products-products .tags').removeClass('full');
      $('.products-listing .products-products .tags .tag').remove();
      $('.menu-link.selected').removeClass('selected');

      // UPDATE THE PRODUCTS
      refreshProducts();

    });

    function initiate() {

      var hash = window.location.hash;

      if (hash != '') {

        var hash_arrays = hash.substr(1).split('&');

        for (var i = 0; i < hash_arrays.length; i++) {

          var name = hash_arrays[i].split('=')[0],
            values = hash_arrays[i].split('=')[1].split('|');

          for (var j = 0; j < values.length; j++) {

            var _link = $('.products-menu .menu-link[data-group=' + name + '][data-id=' + values[j] + ']'),
              group = name,
              id = values[j];

            if (group != 'style') {

              id = parseInt(id);
            }

            // SET THE LINK SELECTED
            _link.addClass('selected');

            // CREATE THE TAG
            var _tag = $('<div/>')
              .addClass('tag')
              .attr('data-group', group)
              .attr('data-id', id)
              .html(_link.html());

            $('.products-products .filters .tags').append(_tag);

            $('.products-listing .products-products .tags').addClass('full');

            // UPDATE THE URL
            updateArrays(group, id);

          }

        }

      }

      refreshProducts();

    }

    // INITIATE THE PRODUCTS
    initiate();

  }

});
