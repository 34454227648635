
$(function() {

    /**
     * CLICK ON THE DROPDOWN
     */
    $('#category').on('change',function(e) {

        window.location = baseUrl + $(this).val();

    });


    if ( $('.publication-publications.publication-wrapper').length > 0 ) {

        var isActive = false,
            grid = '';


        /**
         * On tak click event
         */
        $('.publication-publications .next-publications').click(function(e) {

            e.preventDefault();

            refreshPublications(true);

        });

        /**
         * Refresh page with call results
         */
        function refreshPublications(addPublications) {

            addPublications = typeof addPublications !== 'undefined' ? addPublications : false;

            var _general = $('.publication-wrapper'),
                _publications = _general.find('.publication-grid'),
                _next = _general.find('.next-publications');

            _general.addClass('loading');

            if ( _next.css('display') == 'none' ) {

                _next.slideDown(200);

            }

            if ( addPublications ) {

                var data = {
                    category:_category,
                    page: $('.publication-wrapper .next-publications').data('next')
                }
            } else {

                _publications.slideUp(400);

                var data = {
                    category:_category
                }
            }

            setTimeout(function() {

                $.ajax({
                    url: PHP_REFRESH,
                    type: "POST",
                    data : data
                }).done(function( data ) {

                    if ( $(data.data).length > 0 ) {

                        var _scrollTop = $(window).scrollTop();

                        // Append the new publications
                        if ( addPublications ) {

                            _publications.append( data.data );

                        } else {

                            _publications.html( data.data );

                        }

                        // Get next_page
                        if ( data.next_page != null ) {

                            _general.removeClass('loading');
                            _next.data('next', data.next_page);

                        } else {

                            _next.slideUp(200);

                        }

                        // Display the publications
                        _publications.show();
                        placePublication();

                        $(document).scrollTop( _scrollTop );

                    } else {

                        _general.find('.no-results').slideDown();
                        _next.slideUp();

                    }

                });
            }, 400);
        }

        function placePublication() {

            if ( isActive ) {

                grid.masonry('destroy');
            }

            grid = $('.publication-grid').masonry({
                itemSelector: '.publication-block'
            });

            // layout Masonry after each image loads
            grid.imagesLoaded().progress( function() {
                grid.masonry('layout');
            });

            isActive = true;
        }


        // INITIATE THE PRODUCTS
        refreshPublications();

    }

});