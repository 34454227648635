
$(function() {
    
    if ( $('.faq').length > 0 ) {
        
        $('.faq-question-box .question, .faq-question-box .number, .faq-question-box .faq-arrow').click(function() {

            var _box = $(this).closest('.faq-question-box');

            if ( _box.hasClass('open') ) {

                _box.removeClass('open')
                    .find('.answer').slideUp();

            } else {

                $('.faq-question-box.open').removeClass('open')
                    .find('.answer').slideUp();

                _box.addClass('open')
                    .find('.answer').slideDown();

            }

        });
        
    }
    
});