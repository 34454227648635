
$(function() {

    if ( $('.sale-points').length > 0 ) {

        // Set the screen
        if ( $(window).width() > 991 ) {

            $('.sale-points').css('height', parseInt( $(window).height() - ( $('.header').height() - 2 ) ));

        }

        // Set your google maps parameters
        var latitude = 45.5017,
            longitude = -73.5673,
            map_zoom = 12;

        // Google map custom marker icon - .png fallback for IE11
        var is_internetExplorer11= navigator.userAgent.toLowerCase().indexOf('trident') > -1;
        var marker_url = '../assets/images/map-marker.png';

        // We define here the style of the map
        var styleArray = [
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#444444"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46bcec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            }
        ];

        // Set google map options
        var map_options = {
            center: new google.maps.LatLng(latitude, longitude),
            zoom: map_zoom,
            panControl: false,
            zoomControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            styles: styleArray
        };

        if ( $(window).width() < 768 ) {

            map_options['draggable'] = false;
        }
        
        // Inizialize the map
        var map = new google.maps.Map(document.getElementById('google-container'), map_options);

        var markers = new Array();

        //add custom buttons for the zoom-in/zoom-out on the map
        function CustomZoomControl(controlDiv, map) {
            //grap the zoom elements from the DOM and insert them in the map 
            var controlUIzoomIn= document.getElementById('cd-zoom-in'),
                controlUIzoomOut= document.getElementById('cd-zoom-out');
            controlDiv.appendChild(controlUIzoomIn);
            controlDiv.appendChild(controlUIzoomOut);

            // Setup the click event listeners and zoom-in or out according to the clicked element
            google.maps.event.addDomListener(controlUIzoomIn, 'click', function() {
                map.setZoom(map.getZoom()+1)
            });
            google.maps.event.addDomListener(controlUIzoomOut, 'click', function() {
                map.setZoom(map.getZoom()-1)
            });
        }

        var zoomControlDiv = document.createElement('div');
        var zoomControl = new CustomZoomControl(zoomControlDiv, map);

        //insert the zoom div on the top left of the map
        map.controls[google.maps.ControlPosition.LEFT_TOP].push(zoomControlDiv);




        // Call AJAX

        var _lat, _lng;

        var geo_options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };

        navigator.geolocation.getCurrentPosition(maPosition, getClients, geo_options);

        
        function maPosition(position) {

            _lat = position.coords.latitude;
            _lng = position.coords.longitude;

            getClients();
        }

        var search = 'geo';

        // Get the clients list
        function getClients(nextPage) {

            var _points = $('.sale-points'),
                _general = $('.sale-points-infos'),
                _clients = _points.find('.sale-points-results'),
                _next = _points.find('.sale-points-next'),
                data = new Array();

            switch( search ) {

                case 'search':

                    data = {
                        search: $('#search input').val()
                    };
                    break;

                case 'address':

                    data = {
                        address: $('#address input').val()
                    };
                    break;

                default:

                    data = {
                        lat: _lat,
                        lng: _lng,
                        page: nextPage ? _next.data('next') : 1
                    };
                    break;

            }

            data['page'] = nextPage ? _next.data('next') : 1;

            _general.addClass('loading');

            if ( !nextPage ) {

                // Remove all boxes
                _general.find('.sale-point').remove();
                // Remove all markers
                removeMarkers();

            }
            
            // Get the clients
            $.ajax({
                url: PHP_CLIENTS,
                type: "POST",
                data : data
            }).done(function( data ) {

                if ( $(data.locations).length > 0 ) {


                    _general.find('.no-results').slideUp();

                    // Add markers
                    addMarkers(data.locations);

                    // Display the new clients
                    _clients.find('.clearfix').remove();
                    _clients.append(data.data);
                    _clients.append($('<div/>').addClass('clearfix'));

                    // Update the next page link
                    if (data.next_page != null) {

                        _next.slideDown(200);
                        _general.removeClass('loading');
                        _next.data('next', data.next_page);

                    } else {

                        _next.slideUp(200);

                    }

                } else {

                    _general.find('.no-results').slideDown();
                    _next.slideUp();
                }

            });
        }

        // Ask the next page
        $('.sale-points-next').click(function(e) {

            e.preventDefault();

            mouseover = -1;

            getClients(true);
        });


        // MAP MANAGER

        // Add new markers
        function addMarkers(locations) {

            var bounds = new google.maps.LatLngBounds(),
                counted = $('.sale-points-results .sale-point').length;

            for(var i = 0; i < locations.length; i++) {
                
                var area = locations[i],
                    marker = new google.maps.Marker({
                    position: new google.maps.LatLng(area['lat'], area['lng']),
                    map: map,
                    visible: true,
                    icon: marker_url,
                    id: parseInt( counted + i )
                });

                marker.addListener('mouseover', function() {

                    imOnYou( $(this) );

                });

                marker.addListener('mouseout', function() {

                    imOver();

                });

                markers.push(marker);

                bounds.extend(new google.maps.LatLng(area['lat'], area['lng']));

            }

            map.fitBounds(bounds);
        }

        // Remove all markers
        function removeMarkers() {

            for(var i = 0; i < markers.length; i++) {
                
                markers[i].setMap(null);
                
            }
            
            markers = new Array();
        }

        // Click on a marker
        function imOnYou( marker ) {

            var id = marker[0].id;

            imOver();

            $('.sale-point:nth-child(' + parseInt( id + 1 ) + ')').addClass('active');

        }

        function imOver( ) {

            $('.sale-point').removeClass('active');

        }

        // Hover the box
        
        var mouseover;
        $('body').on('mouseover', '.sale-point', function() {
            
            var id = $(this).index();
            
            if ( id != mouseover ) {

                mouseover = id;

                var marker = markers[id];

                marker.setAnimation(google.maps.Animation.BOUNCE);
                map.panBy(1, 1);
                
                $(this).addClass('active');
                
            }

        }).on('mouseleave', '.sale-point', function() {

            for(var i = 0; i < markers.length; i++) {

                markers[i].setAnimation(null);

            }
            
            $(this).removeClass('active');
        
        });

        // Check the forms
        $('#search').submit(function(e) {

            e.preventDefault();

            var val = $('#search input').val();
            
            if ( val != '' ) {

                search = 'search';
            } else {

                search = 'geo';
            }

            $('#address input').val('');

            getClients();

        });

        $('#address').submit(function(e) {

            e.preventDefault();

            var val = $('#address input').val();

            if ( val != '' ) {

                search = 'address';
            } else {

                search = 'geo';
            }

            $('#search input').val('');

            getClients();

        });

    }

});