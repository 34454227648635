
$(function() {

    if ( $('.product-item').length > 0 ) {

        $('.product-accordion-item').first().addClass('open');

        $('.product-accordion .product-accordion-title').click(function() {

            var _item = $(this).closest('.product-accordion-item');

            if ( _item.hasClass('open') ) {

                _item.removeClass('open')
                    .find('.product-accordion-value').slideUp(200);

            } else {

                $('.product-accordion-item.open').removeClass('open')
                    .find('.product-accordion-value').slideUp(200);

                _item.addClass('open')
                    .find('.product-accordion-value').slideDown(200);

            }

        });

    }

});