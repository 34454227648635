
$(function() {
    
    if ( $('.faucetVisualisationSlider').length > 0 ) {

        // Change Background
        $('.resultSwitch').click(function () {

            if ( !$(this).hasClass('active') ) {

                var index = $(this).index(),
                    coef = parseInt( index * -100 ),
                    _imageFaucet = $('.faucentVisualisationImage');

                $('.resultSwitch').removeClass('active');
                $(this).addClass('active');
                _imageFaucet.removeClass('index-0 index-1 index-2 index-3').addClass('index-' + index);

                $('.faucetVisualisationSlider').css('transform', 'translateX(' + coef + 'vw)');
            }
        });


        // Save the result
        $('.resultLink.save').click(function(e) {
            
            e.preventDefault();

            $('#savingResult').empty();

            var c = document.getElementById('faucetVisualisation');

            html2canvas(c, {
                onrendered: function(canvas) {
                    // canvas is the final rendered <canvas> element
                    $('#savingResult').append( canvas );

                    var can = $('#savingResult').find('canvas')[0];
                    var img = new Image();
                    img.src = can.toDataURL();

                    var lightbox = lity();
                    lightbox(img);
                }
            });
        });

        var _contestPopup = $('.contest_popup'),
            _closePopup = $('.contest_popup .close_contest'),
            _submitPopup = _contestPopup.find('button.submit'),
            _formPopup = _contestPopup.find('form');

        _closePopup.click(function() {

            _contestPopup.removeClass('active');
        });

        // Share the result
        $('.resultLink.share').click(function(e) {

            e.preventDefault();

            // _contestPopup.addClass('active');

            $('#savingResult').empty();

            var c = document.getElementById('faucetVisualisation');

            html2canvas(c, {
                onrendered: function(canvas) {
                    // canvas is the final rendered <canvas> element
                    $('#savingResult').append( canvas );

                    var can = $('#savingResult').find('canvas')[0];
                    var img = new Image();
                    img.src = can.toDataURL();

                    // Save it to the database
                    var data = {
                        code : $('.faucentVisualisationImage').data('id'),
                        background : $('.resultSwitch.active').data('background'),
                        image: img.src
                    };

                    $.post( POST_URL, data, function(data) {
                        var url = 'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(data) + '&title=' + encodeURIComponent($(document).find("title").text());

                        PopupCenter(url, 600, 600);
                    } );
                }
            });

            return false;
        });

        _submitPopup.click(function() {

            _contestPopup.removeClass('active');

            $('#savingResult').empty();

            var c = document.getElementById('faucetVisualisation');

            html2canvas(c, {
                onrendered: function(canvas) {
                    // canvas is the final rendered <canvas> element
                    $('#savingResult').append( canvas );

                    var can = $('#savingResult').find('canvas')[0];
                    var img = new Image();
                    img.src = can.toDataURL();

                    // Save it to the database
                    var data = {
                        code : $('.faucentVisualisationImage').data('id'),
                        background : $('.resultSwitch.active').data('background'),
                        name: $('.contest_popup input[name=name]').val(),
                        email: $('.contest_popup input[name=email]').val(),
                        newsletter: ($('#contest-newsletter').is(':checked')) ? 1 : 0,
                        image: img.src
                    };

                    _formPopup[0].reset();

                    $.post( POST_URL, data, function(data) {
                        var url = 'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(data) + '&title=' + encodeURIComponent($(document).find("title").text());

                        PopupCenter(url, 600, 600);
                    } );
                }
            });

            return false;
        });

        function PopupCenter(url, w, h) {
            // Fixes dual-screen position                         Most browsers      Firefox
            var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
            var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

            var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

            var left = ((width / 2) - (w / 2)) + dualScreenLeft;
            var top = ((height / 2) - (h / 2)) + dualScreenTop;
            var newWindow = window.open(url, 'Riobel', 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

            // Puts focus on the newWindow
            if (window.focus) {
                newWindow.focus();
            }
        }
    }
    
});