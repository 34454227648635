
$(function() {

    if ( $('.support-menu.search').length > 0 ) {

        var isActive = false;

        $('.support-menu-link').click(function(e) {

            e.preventDefault();

            if ( !$(this).hasClass('active') ) {

                // $('.no-results').slideUp(200);
                // $('.next-products').slideDown(200);

                // TAB

                if ( $(window).width() < 767 ) {

                    var _new = $(this).text();

                    $('.support-menu-mobile span').html( _new );
                }

                $('.support-menu-link.active').removeClass('active');
                $(this).addClass('active');

                // PAGE

                var target = $(this).data('target');

                $('.support-content.active').removeClass('active').hide();

                $('.support-content.' + target).addClass('active').fadeIn();

                // CLOSE

                $('.support-menu-mobile').click();

                refreshResults( target, 'change' )

            }

        });


        function refreshResults( category, page ) {

            var next, content, noProducts,
                search = $('.support-search input').val();

            switch ( category ) {
                case 'publications':
                    content = $('.publication-grid');
                    next = $('.support-content.publications .next-products');
                    noProducts = $('.support-content.publications .no-results');
                    break;
                
                default:
                    content = $('.products-list');
                    next = $('.support-content.products .next-products');
                    noProducts = $('.support-content.products .no-results');
                    break;
            }

            if ( page == 'change' ) {

                if ( content.hasClass('done') ) {

                    return;
                } else {

                    page = 1;
                }
            }

            $('.partial-search').addClass('loading');

            $.ajax({
                url: PHP_SEARCH,
                type: "POST",
                data : {
                    search: search,
                    page: page,
                    category: category
                }
            }).done(function( data ) {

                // MAJ DES CHIFFRES
                if ( !$('.support-menu').hasClass('done') ) {

                    $.each(data.count, function (index, value) {

                        var menu = $('.support-menu-link[data-target=' + index + ']');

                        if ( !menu.hasClass('done') ) {

                            menu.find('span').text( value );
                            menu.addClass('done');
                        }

                        if (menu.hasClass('active')) {

                            $('.support-menu-mobile').html( menu.html() );
                        }
                    });

                    $('.support-menu').addClass('done');
                }

                if ( $(data.data).length > 0 ) {

                    // MAJ DU CONTENU
                    content.append( data.data );

                    // MAJ DU BOUTON
                    if ( data.next_page == null ) {
                        
                        next.slideUp(200);
                    } else {

                        next.data('next', data.next_page);
                    }

                    // MASONRY
                    if ( category == 'publications' ) {

                        placePublication();
                    }

                } else {

                    next.slideUp(200);
                    noProducts.slideDown(200);
                }

                $('.partial-search').removeClass('loading');
                content.addClass('done');
            });

        }

        function placePublication() {

            if ( isActive ) {

                grid.masonry('destroy');
            }

            grid = $('.publication-grid').masonry({
                itemSelector: '.publication-block'
            });

            // layout Masonry after each image loads
            grid.imagesLoaded().progress( function() {
                grid.masonry('layout');
            });

            isActive = true;
        }

        $('.next-products').click(function() {

            var category = $(this).data('target');
            var page = $(this).data('next');

            refreshResults( category, page );
        });

        if ( $('.support-search input').val().length > 1 ) {
            
            refreshResults($('.search .support-menu-link.active').data('target'), 1);

        }else{
            $('.next-products').hide();
        }

    }

});