$(function () {

  if ($('.products-listing-momenti').length > 0) {

    var _space = [],
      _identification = [],
      _accessories = [],
      onAjax = false,
      ajaxCall = false;

    // CLICK ON THE MENU
    $('.products-menu .menu-link').click(function (e) {

      e.preventDefault();

      // GET THE INFOS
      var group = $(this).data('group'),
        id = $(this).data('id');

      // if ( $(this).hasClass('selected') ) {
      //
      //     removeTag(group, id);
      //
      // } else {
      //
      //     addTag($(this), group, id);
      //
      // }

      addTag($(this), group, id);

    });

    // CLICK ON THE PRODUCT COLLECTION
    $('body').on('click', '.products-list .menu-link', function () {

      // GET THE INFOS
      var group = $(this).data('group'),
        id = $(this).data('id'),
        _link = $('.products-menu .menu-link[data-group=' + group + '][data-id=' + id + ']');

      _link.click();

    });

    /**
     * On tak click event
     */
    $(document).on('click', '.products-products .tag', function (e) {

      // GET THE INFOS
      var group = $(this).data('group'),
        id = $(this).data('id');

      removeTag(group, id);

    });

    /**
     * On tak click event
     */
    $('.products-products .next-products .button').click(function (e) {

      e.preventDefault();

      refreshProducts(true);

    });

    /**
     * Remove tag from array
     * @param group
     * @param id
     */
    function removeTag (group, id) {

      // REMOVE NEW SEARCH
      if ($('.products-products .tags .tag').length == 1) {

        $('.products-products .tags').removeClass('full');

      }

      var _tag = $('.products-products .tag[data-group=' + group + '][data-id=' + id + ']'),
        _menu = $('.products-menu .menu-link[data-group=' + group + '][data-id=' + id + ']');

      _tag.remove();
      _menu.removeClass('selected');

      updateArrays(group, id);

      // UPDATE THE PRODUCTS
      refreshProducts();

    }

    /**
     * Remove tag from array
     * @param group
     * @param id
     */
    function addTag (_link, group, id) {

      // SET THE LINK SELECTED
      $('.products-menu .menu-link').removeClass('selected');
      _link.addClass('selected');

      // CREATE THE TAG
      var _tag = $('<div/>')
        .addClass('tag')
        .attr('data-group', group)
        .attr('data-id', id)
        .html(_link.html());

      $('.products-products .filters .tags').append(_tag);

      // UPDATE THE URL
      updateArrays(group, id);

      // ADD NEW SEARCH
      $('.products-listing-momenti .products-products .filters .tags').empty();
      $('.products-listing-momenti .products-products .tags').addClass('full');

      // UPDATE THE PRODUCTS
      refreshProducts();

    }

    /**
     * Update the arrays with parameters
     * @param group
     * @param id
     */
    function updateArrays (group, id) {

      _space = [];
      _identification = [];
      _accessories = [];

      switch (group) {

        case 'space':
          if (jQuery.inArray(id, _space) > -1) {

            _space.splice($.inArray(id, _space), 1);

          } else {

            _space.push(id);

          }
          break;

        case 'identification':
          if (jQuery.inArray(id, _identification) > -1) {

            _identification.splice($.inArray(id, _identification), 1);

          } else {

            _identification.push(id);

          }
          break;

        case 'accessories':
          if (jQuery.inArray(id, _accessories) > -1) {

            _accessories.splice($.inArray(id, _accessories), 1);

          } else {

            _accessories.push(id);

          }
          break;

      }
    }

    /**
     * Refresh page with call results
     */
    function refreshProducts (addProducts) {

      if (ajaxCall) {

        ajaxCall.abort();
      }

      addProducts = typeof addProducts !== 'undefined' ? addProducts : false;

      var _general = $('.products-listing-momenti .products-products'),
        _products = $('.products-listing-momenti .product-block-row'),
        _next = $('.products-listing-momenti .next-products');

      $('.products-listing-momenti .no-results').slideUp();
      _next.slideDown();

      _general.addClass('loading');

      if (_next.css('display') == 'none') {

        _next.slideDown(200);

      }

      if (addProducts) {

        var data = {
          space: _space,
          identification: _identification,
          accessories: _accessories,
          page: $('.products-products .next-products').data('next')
        };
      } else {

        _products.slideUp(400);

        var data = {
          space: _space,
          identification: _identification,
          accessories: _accessories
        };
      }

      setTimeout(function () {

        ajaxCall = $.ajax({
          url: PHP_REFRESH,
          type: 'POST',
          data: data
        }).done(function (data) {

          if ($(data.data).length > 0) {

            console.log(_products.find('.products-list'));

            // Append the new products
            if (addProducts) {

              _products.find('.products-list').append(data.data);

            } else {

              _products.find('.products-list').html(data.data);

            }

            // Get next_page
            if (data.next_page != null) {

              _general.removeClass('loading');
              _next.data('next', data.next_page);

            } else {

              _next.slideUp(200);

            }

            if (!$('.product-item').length > 0) {

              // Update the URL
              if (typeof(data.url) !== 'undefined') {
                window.location.hash = data.url;
              } else {
                history.pushState('', document.title, window.location.pathname
                  + window.location.search);
              }

            }

            // Display the products
            _products.slideDown(400);

          } else {

            $('.products-listing-momenti .no-results').slideDown();
            _next.slideUp();
          }

          ajaxCall = false;
        });
      }, 400);
    }

    $('.new_search').click(function () {

      // REINITIATE VALUES
      _space = [];
      _identification = [];
      _accessories = [];

      // REMOVE TAGS
      $('.products-listing-momenti .products-products .tags').removeClass('full');
      $('.products-listing-momenti .products-products .tags .tag').remove();
      $('.menu-link.selected').removeClass('selected');

      // UPDATE THE PRODUCTS
      refreshProducts();

    });

    function initiate () {

      var hash = window.location.hash;

      if (hash != '') {

        var hash_arrays = hash.substr(1).split('&');

        for (var i = 0; i < hash_arrays.length; i++) {

          var name = hash_arrays[i].split('=')[0],
            values = hash_arrays[i].split('=')[1].split('|');

          for (var j = 0; j < values.length; j++) {

            var _link = $('.products-menu .menu-link[data-group=' + name + '][data-id=' + values[j] + ']'),
              group = name,
              id = values[j];

            if (group != 'style') {

              id = parseInt(id);
            }

            // SET THE LINK SELECTED
            _link.addClass('selected');

            // CREATE THE TAG
            var _tag = $('<div/>')
              .addClass('tag')
              .attr('data-group', group)
              .attr('data-id', id)
              .html(_link.html());

            $('.products-products .filters .tags').append(_tag);

            $('.products-listing-momenti .products-products .tags').addClass('full');

            // UPDATE THE URL
            updateArrays(group, id);

          }

        }

      }

      refreshProducts();
    }

    // INITIATE THE PRODUCTS
    initiate();

  }

});