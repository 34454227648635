
$(function() {

    if ( $('.products-menu').length > 0 ) {

        // SHOW / HIDE MENU
        $('.show_all').click(function() {

            if ( $(this).hasClass('less') ) {

                $(this).closest('.group').find('.show_row').slideUp();
                $(this).removeClass('less');

            } else {

                $(this).closest('.group').find('.show_row').slideDown();
                $(this).addClass('less');

            }

        });

        // MOBILE MENU
        $('.products-menu .group .title-3').click(function() {

            if ( $(window).width() < 767 ) {

                if ( $(this).hasClass('open') ) {

                    $(this).find('.tcon').removeClass('tcon-transform');
                    $(this).closest('.group').find('ul').slideUp(100);
                    $(this).removeClass('open');

                } else {

                    $(this).find('.tcon').addClass('tcon-transform');
                    $(this).closest('.group').find('ul').slideDown(100);
                    $(this).addClass('open');

                }

            }

        });

    }

});