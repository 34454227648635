
$(function() {

    if ( $('.product-share').length > 0 ) {

        $('.share-cell.share').click(function() {

            if ( $('.product-share').hasClass('active') ) {

                $('.product-share').removeClass('active').fadeOut();

            } else {

                $('.product-share').addClass('active').fadeIn();

            }

        });

        $('.product-share .close').click(function() {

            $('.product-share').removeClass('active').fadeOut();

        });

    }

});