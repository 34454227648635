
$(function() {

    if ( $('.partial-search').length > 0 && $(window).width() > 991 ) {

        $(window).scroll(function(){

            checkScroll();

        });

        checkScroll();

        function checkScroll() {

            var _mainnav = $('.main-nav'),
                _supporttitle = $('.support-title.search'),
                _poststicky = $('.search-post-sticky'),
                _supportmenu = $('.support-menu');


            if ( _mainnav.hasClass('fixed') && !_supporttitle.hasClass('fixed') ) {

                _supporttitle.addClass('fixed').css('top', _mainnav.outerHeight()).css('left', _supportmenu.width());
                _poststicky.css('padding-top', _supporttitle.outerHeight());

            } else if ( !_mainnav.hasClass('fixed') && _supporttitle.hasClass('fixed') ) {

                _supporttitle.removeClass('fixed');
                _poststicky.css('padding-top', 0);

            }

        }

    }

});