
$(function() {

    if ( $('.home-buttons.inspiration').length > 0 ) {

        if ( $(window).width() > 991 ) {

            var totop = parseInt($('.home-buttons.inspiration').position()['top'] + $('.home-buttons.inspiration').height() - $('.main-nav').height());

        } else {

            var totop = parseInt($('.home-buttons.inspiration').position()['top'] + $('.home-buttons.inspiration').height() );

        }

        setTimeout(function(){

            $("html, body").animate({scrollTop: totop }, 1000);

        }, 2000);

    }

});